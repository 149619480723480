import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { ScrollArea } from "./ui/scroll-area";
import { MessagesSquare } from 'lucide-react';

const SMSDialog = ({ contact, open, onOpenChange }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && contact) {
      fetchMessages();
    }
  }, [open, contact]);

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/contacts/${contact.id}/sms`);
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Error fetching SMS messages:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>SMS Communications - {contact?.name}</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[400px] w-full rounded-md border p-4">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              Loading messages...
            </div>
          ) : (
            <div className="space-y-4">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`rounded-lg p-3 max-w-[80%] ${
                    message.direction === 'OUTBOUND'
                      ? 'ml-auto bg-blue-500 text-white'
                      : 'bg-gray-100'
                  }`}
                >
                  <p>{message.messageContent}</p>
                  <div className="text-xs mt-1 opacity-70">
                    {new Date(message.sentAt).toLocaleString()}
                  </div>
                </div>
              ))}
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default function SMSButton({ contact }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        variant="outline"
        size="sm"
        className="flex gap-2 items-center"
      >
        <MessagesSquare className="w-4 h-4" />
        View SMS
      </Button>
      <SMSDialog
        contact={contact}
        open={dialogOpen}
        onOpenChange={setDialogOpen}
      />
    </>
  );
}
import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { removeAuthToken, removeUser } from '../utils/auth';
import { Button } from "./ui/button";
import {
  Home,
  Building2,
  Users,
  Phone,
  FileText,
  CreditCard,
  History,
  Upload,
  Settings,
  Calendar,
  Bot,
  PhoneCall,
  PhoneOutgoing,
  LogOut,
  Menu,
  User,
  ChevronLeft,
  ChevronRight
} from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "./ui/sheet";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { NotificationBell } from './NotificationBell';


const Layout = ({ children, isAdmin }) => {
  const { pathname } = useLocation();
  const shouldShowSidebar = pathname !== '/home' && pathname !== '/pricing';
  const { organizationId, setNavigationEnabled } = useContext(OrganizationContext);

  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleLogout = () => {
    removeAuthToken();
    removeUser();
    navigate('/login');
  };

  const MenuLink = ({ to, icon: Icon, children, disabled }) => {
    const linkContent = (
      <>
        <Icon className="h-4 w-4 min-w-[1rem]" />
        {!isCollapsed && <span className="truncate">{children}</span>}
      </>
    );

    return (
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            {disabled ? (
              <div className={`flex items-center rounded-lg px-3 py-2 text-gray-300 cursor-not-allowed ${
                isCollapsed ? 'justify-center px-2' : 'gap-3'
              }`}>
                {linkContent}
              </div>
            ) : (
              <Link
                to={to}
                className={`flex items-center rounded-lg px-3 py-2 text-gray-500 transition-all hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50 ${
                  isCollapsed ? 'justify-center px-2' : 'gap-3'
                }`}
              >
                {linkContent}
              </Link>
            )}
          </TooltipTrigger>
          <TooltipContent side="right">
            {disabled ? 'Complete organization setup and subscription first' : (isCollapsed ? children : '')}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  const Sidebar = () => {
    const { navigationEnabled } = useContext(OrganizationContext);
    const currentPath = useLocation().pathname;
  
    return (
      <aside className={`fixed left-0 top-0 bottom-0 z-40 hidden lg:flex flex-col transition-all duration-300 ease-in-out ${
        isCollapsed ? 'w-16' : 'w-64'
      }`}>
        <div className="flex flex-col h-full border-r bg-white dark:bg-gray-800">
          <div className="flex-shrink-0 p-4">
            <Button
              variant="ghost"
              size="icon"
              className="absolute -right-3 top-6 z-50 rounded-full border shadow-md bg-white dark:bg-gray-800"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? 
                <ChevronRight className="h-4 w-4" /> : 
                <ChevronLeft className="h-4 w-4" />
              }
            </Button>
          </div>
  
          <div className="flex-1 overflow-y-auto">
            <div className={`px-3 py-2 ${isCollapsed ? 'px-2' : 'px-3'}`}>
              <nav className="space-y-1">
                <MenuLink to="/" icon={Home} disabled={!navigationEnabled}>Dashboard</MenuLink>
                <MenuLink to="/organization" icon={Building2}>Organization</MenuLink>
                <MenuLink to="/subscriptions" icon={CreditCard} disabled={!navigationEnabled}>Subscriptions</MenuLink>
                <MenuLink to="/appointments" icon={Calendar} disabled={!navigationEnabled}>Appointment Settings</MenuLink>
                <MenuLink to="/upload-files" icon={Upload} disabled={!navigationEnabled}>Upload Files</MenuLink>
                <MenuLink to="/vapi-assistants" icon={Bot} disabled={!navigationEnabled}>Assistant List</MenuLink>
                {/* All other menu items disabled when navigationEnabled is false */}
                <MenuLink to="/contact-management" icon={Users} disabled={!navigationEnabled}>Contact Management</MenuLink>
                {/* <MenuLink to="/call-summary" icon={FileText} disabled={!navigationEnabled}>Call Summary</MenuLink> */}
                <MenuLink to="/call-track" icon={Phone} disabled={!navigationEnabled}>Call Tracking</MenuLink>
                <MenuLink to="/themed-call-summary" icon={FileText} disabled={!navigationEnabled}>Themed Call Summary</MenuLink>
                {/* <MenuLink to="/themed-call-track" icon={Phone} disabled={!navigationEnabled}>Themed Call Tracking</MenuLink> */}
                <MenuLink to="/monthly-billing" icon={CreditCard} disabled={!navigationEnabled}>Monthly Billing</MenuLink>
                <MenuLink to="/payment-history-component" icon={History} disabled={!navigationEnabled}>Payment History</MenuLink>
                <MenuLink to="/vapi-phone-numbers" icon={Phone} disabled={!navigationEnabled}>Phone Numbers</MenuLink>
                <MenuLink to="/vapi-outbound-calls" icon={PhoneOutgoing} disabled={!navigationEnabled}>Outbound Dial</MenuLink>
                <MenuLink to="/sms-conversations" icon={PhoneOutgoing} disabled={!navigationEnabled}>SMS Conversations</MenuLink>
                {isAdmin && (
                  <MenuLink to="/user-management" icon={Settings} disabled={!navigationEnabled}>User Management</MenuLink>
                )}
              </nav>
            </div>
          </div>
        </div>
      </aside>
    );
  };

  const MobileSidebar = () => {
    const { navigationEnabled } = useContext(OrganizationContext);
    return (
    <div className="space-y-4 py-4">
      <div className="px-3 py-2">
        <div className="space-y-1">
          <MenuLink to="/" icon={Home}>Dashboard</MenuLink>
          <MenuLink to="/organization" icon={Building2}>Organization</MenuLink>
          <MenuLink to="/contact" icon={Users}>Contact Management</MenuLink>
          <MenuLink to="/call-summary" icon={FileText}>Call Summary</MenuLink>
          <MenuLink to="/call-track" icon={Phone}>Call Tracking</MenuLink>
          <MenuLink to="/monthly-billing" icon={CreditCard}>Monthly Billing</MenuLink>
          {/* <MenuLink to="/stripe-payment-component" icon={CreditCard}>Payment</MenuLink> */}
          <MenuLink to="/payment-history-component" icon={History}>Payment History</MenuLink>
          <MenuLink to="/vapi-phone-numbers" icon={Phone}>Phone Numbers</MenuLink>
          <MenuLink to="/upload-files" icon={Upload}>Upload Files</MenuLink>
          <MenuLink to="/subscriptions" icon={CreditCard}>Subscriptions</MenuLink>
          <MenuLink to="/appointments" icon={Calendar}>Appointment Settings</MenuLink>
          <MenuLink to="/vapi-assistants" icon={Bot}>Assistant List</MenuLink>
          {/* <MenuLink to="/vapi-inbound-calls" icon={PhoneCall}>Inbound Calls</MenuLink> */}
          <MenuLink to="/vapi-outbound-calls" icon={PhoneOutgoing}>Outbound Dial</MenuLink>
          {isAdmin && (
            <MenuLink to="/user-management" icon={Settings}>User Management</MenuLink>
          )}
        </div>
      </div>
    </div>
  );
}

  return (
    <div className="min-h-screen">
      {/* Mobile Trigger */}
      {shouldShowSidebar  && (
      <Sheet>
        <SheetTrigger asChild>
          <Button 
            variant="outline" 
            size="icon" 
            className="fixed top-4 left-4 z-50 lg:hidden"  // Changed positioning and z-index
          >
            <Menu className="h-6 w-6" />
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="w-[240px] p-0">  {/* Adjusted width and padding */}
          <div className="pt-16"> {/* Added padding top to avoid overlap with header */}
            <MobileSidebar />
          </div>
        </SheetContent>
      </Sheet>
      )}

      {/* Desktop Sidebar */}
      {shouldShowSidebar  && <Sidebar />}
  
      {/* Main Content */}
      <div className={`flex flex-col min-h-screen ${
        shouldShowSidebar ? (isCollapsed ? 'lg:ml-16' : 'lg:ml-64') : ''
      }`}>
        {/* Navbar */}
        <header className="sticky top-0 z-40 border-b bg-white dark:bg-gray-800">
          <div className="flex h-16 items-center px-4 justify-between">
            <div className="flex items-center">
            <img
                src="/krupa.jpg"
                className="h-8 w-auto mr-2"
              />

              <div className="flex items-center">
                <Phone className="h-8 w-8 text-indigo-600" />
                <span className="ml-2 text-xl font-bold text-gray-900">AI Phone Agent</span>
              </div>
            </div>
            <div className="flex items-center gap-4">
            <NotificationBell />
              <Link to="/profile">
                <Button variant="ghost" size="icon">
                  <User className="h-5 w-5" />
                </Button>
              </Link>
              <Button variant="ghost" size="icon" onClick={handleLogout}>
                <LogOut className="h-5 w-5" />
              </Button>
            </div>
          </div>
        </header>
  
        {/* Page Content */}
        <main className="flex-1 p-4">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";
import { Alert, AlertDescription } from "./ui/alert";
import { Check, CreditCard } from "lucide-react";
import { getPricingPlans } from "../services/api";

const PricingSection = ({ onPlanSelected, hideNavigation = false }) => {
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  

  useEffect(() => {
    fetchPricingPlans();
  }, []);

  const fetchPricingPlans = async () => {
    try {      
      const data = await getPricingPlans();
      console.log('fetching plan data='+JSON.stringify(data));
      setPlans(data);
    } catch (error) {
      setError('Failed to load pricing plans. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleGetStarted = async (planType) => {
    try {
      setIsProcessing(true);
      setError('');
      
      const selectedPlan = plans.find(plan => plan.planType === planType);
      
      if (!selectedPlan) {
        throw new Error('Selected plan configuration not found');
      }
      
      const planDetails = {
        type: planType,
        billing: billingPeriod,
        price: billingPeriod === 'monthly' ? selectedPlan.monthlyPrice : selectedPlan.yearlyPrice,
        subscriptionPlan: planType.toUpperCase(),
        subscriptionFreq: billingPeriod === 'monthly' ? 'MONTHLY' : 'YEARLY',
        subscriptionAmount: billingPeriod === 'monthly' ? selectedPlan.monthlyPrice : selectedPlan.yearlyPrice,
        priceId: billingPeriod === 'monthly' ? selectedPlan.monthlyPriceId : selectedPlan.yearlyPriceId,
        description: `${planType} Plan - ${selectedPlan.minutesPerMonth} minutes/month`,
        productName: `${planType.charAt(0).toUpperCase() + planType.slice(1)} Plan`,
        isActive: true,
        startDate: new Date().toISOString().split('T')[0]
      };
      
      if (hideNavigation && onPlanSelected) {
        onPlanSelected(planDetails);
      } else {
        localStorage.setItem('selectedPlan', JSON.stringify(planDetails));
        navigate('/register');
      }
    } catch (error) {
      setError('Failed to process your request. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const PricingCard = ({ plan, billingPeriod, isProcessing, onSelect }) => {
    const price = billingPeriod === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice;
    const features = Array.isArray(plan.features) ? plan.features : [];
  
    return (
      <Card className={`relative ${plan.isPopular ? 'border-primary shadow-lg' : ''}`}>
        {plan.isPopular && (
          <Badge className="absolute top-4 right-4">
            Most Popular
          </Badge>
        )}
        <CardHeader>
          <CardTitle className="capitalize">{plan.planType} Plan</CardTitle>
          <CardDescription>
            {plan.planType === 'basic' ? 'Perfect to get started' :
             plan.planType === 'premium' ? 'Best for growing businesses' :
             'For large enterprises'}
          </CardDescription>
          <div className="mt-4">
            <span className="text-3xl font-bold">£{price}</span>
            <span className="text-muted-foreground ml-1">
              /{billingPeriod === 'monthly' ? 'mo' : 'yr'}
            </span>
          </div>
        </CardHeader>
        <CardContent>
          <ul className="space-y-3">
            {features.map((feature, index) => (
              <li key={`${plan.planType}-feature-${index}`} className="flex items-center">
                <Check className="w-4 h-4 text-primary mr-2" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
          <Button
            className="w-full mt-6"
            variant={plan.isPopular ? "default" : "outline"}
            onClick={() => onSelect(plan.planType)}
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Get Started"}
          </Button>
        </CardContent>
      </Card>
    );
  };

  if (loading) {
    return <div className="text-center py-12">Loading pricing plans...</div>;
  }

  return (
    <div className="container max-w-6xl mx-auto py-12 px-4">
      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">Choose Your Plan</h2>
        <p className="text-muted-foreground mb-8">
          Whether you're just getting started or scaling up, we've got you covered.
        </p>
        
        <div className="flex items-center justify-center gap-4">
          <Label htmlFor="billing-toggle">Monthly</Label>
          <Switch
            id="billing-toggle"
            checked={billingPeriod === 'yearly'}
            onCheckedChange={(checked) => 
              setBillingPeriod(checked ? 'yearly' : 'monthly')
            }
          />
          <div className="flex items-center gap-2">
            <Label htmlFor="billing-toggle">Yearly</Label>
            <Badge variant="secondary">Save 15%</Badge>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
      {Array.isArray(plans) && plans.map((plan) => (
          <PricingCard 
            key={`${plan.planType}-${billingPeriod}`} 
            plan={plan}
            billingPeriod={billingPeriod}
            isProcessing={isProcessing}
            onSelect={handleGetStarted}
          />
        ))}
      </div>

      <div className="mt-12 text-center text-sm text-muted-foreground">
        <p>All plans include 24/7 support and a 30-day money-back guarantee.</p>
        <p className="mt-2">Need a custom plan? <a href="/contact" className="p-0">Contact us</a></p>
      </div>
    </div>
  );
};

export default PricingSection;
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getVAPIAssistantsForOrg, createVAPIAssistant, deleteVAPIAssistant, createVAPIAssistantCall, getLiveVAPIAssistant, getAllLiveVAPIAssistants, deleteLiveVAPIAssistant, getPublishedVAPIAssistants } from '../services/api';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Alert, AlertDescription } from "./ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import {
  Trash2,
  Edit,
  PhoneCall,
  CheckCircle,
  AlertCircle,
  Loader2,
  Download
} from "lucide-react";

const AssistantList = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [assistants, setAssistants] = useState([]);
  const [liveAssistants, setLiveAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [loading, setLoading] = useState(true);

  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [publishedAssistants, setPublishedAssistants] = useState([]);
  const [loadingPublished, setLoadingPublished] = useState(false);
  const [importError, setImportError] = useState(null);
  const [importingId, setImportingId] = useState(null);

  const fetchPublishedAssistants = async () => {
    try {
      setLoadingPublished(true);
      setImportError(null);
      const data = await getPublishedVAPIAssistants();
      setPublishedAssistants(data);
    } catch (error) {
      setImportError(error.message);
    } finally {
      setLoadingPublished(false);
    }
  };

  const handleImport = async (assistant) => {
    try {
      setImportingId(assistant.id);
      setImportError(null);

      const transformedAssistant = {
        name: assistant.name,
        transcriber: JSON.stringify(assistant.transcriber),
        model: JSON.stringify(assistant.model),
        voice: JSON.stringify(assistant.voice),
        firstMessageMode: assistant.firstMessageMode,
        recordingEnabled: assistant.recordingEnabled,
        hipaaEnabled: assistant.hipaaEnabled,
        clientMessages: JSON.stringify(assistant.clientMessages),
        serverMessages: JSON.stringify(assistant.serverMessages),
        silenceTimeoutSeconds: assistant.silenceTimeoutSeconds,
        maxDurationSeconds: assistant.maxDurationSeconds,
        backgroundSound: assistant.backgroundSound,
        backchannelingEnabled: assistant.backchannelingEnabled,
        backgroundDenoisingEnabled: assistant.backgroundDenoisingEnabled,
        modelOutputInMessagesEnabled: assistant.modelOutputInMessagesEnabled,
        transportConfigurations: JSON.stringify(assistant.transportConfigurations),
        firstMessage: assistant.firstMessage,
        voicemailDetection: JSON.stringify(assistant.voicemailDetection),
        voicemailMessage: assistant.voicemailMessage,
        endCallMessage: assistant.endCallMessage,
        endCallPhrases: JSON.stringify(assistant.endCallPhrases),
        metadata: JSON.stringify(assistant.metadata),
        serverUrl: assistant.serverUrl,
        analysisPlan: JSON.stringify(assistant.analysisPlan),
        artifactPlan: JSON.stringify(assistant.artifactPlan),
        messagePlan: JSON.stringify(assistant.messagePlan),
        startSpeakingPlan: JSON.stringify(assistant.startSpeakingPlan),
        stopSpeakingPlan: JSON.stringify(assistant.stopSpeakingPlan),
        credentialIds: JSON.stringify(assistant.credentialIds)
      };

      await createVAPIAssistant(transformedAssistant);
      await fetchAssistants();
      setImportDialogOpen(false);
    } catch (error) {
      setImportError(error.message);
    } finally {
      setImportingId(null);
    }
  };

  useEffect(() => {
    if (importDialogOpen) {
      fetchPublishedAssistants();
    }
  }, [importDialogOpen]);  

  useEffect(() => {
    console.log('fetchAssistants organizationId='+organizationId);
    fetchAssistants(organizationId);
    fetchAllLiveAssistants(organizationId);
  }, [organizationId]);

  const fetchAssistants = async (organizationId) => {
    try {
      const data = await getVAPIAssistantsForOrg(organizationId);
      setAssistants(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch assistants:', error);
      setLoading(false);
    }
  };

  const fetchLiveAssistants = async () => {
    try {
      const data = await getLiveVAPIAssistant(selectedAssistant?.id);
      setLiveAssistants(data);
    } catch (error) {
      console.error('Failed to fetch live assistants:', error);
    }
  };

  const fetchAllLiveAssistants = async (organizationId) => {
    try {
      const data = await getAllLiveVAPIAssistants(organizationId);
      setLiveAssistants(data);
    } catch (error) {
      console.error('Failed to fetch live assistants:', error);
    }
  };

  const handleDeleteAssistant = async (id) => {
    if (window.confirm('Are you sure you want to delete this assistant?')) {
      try {
        await deleteVAPIAssistant(id);
        setAssistants(assistants.filter(assistant => assistant.id !== id));
      } catch (error) {
        console.error('Failed to delete assistant:', error);
      }
    }
  };

  const handleCreateCall = async (id) => {
    try {
      const response = await createVAPIAssistantCall(id);
      if (response.assistantId) {
        alert('Call created successfully');
        fetchLiveAssistants();
      }
    } catch (error) {
      console.error('Failed to create call:', error);
      alert('Failed to create call');
    }
  };

  const handleDeleteLiveAssistant = async (assistantId) => {
    if (window.confirm('Are you sure you want to delete this Live assistant?')) {
      try {
        await deleteLiveVAPIAssistant(assistantId);
        setLiveAssistants(liveAssistants.filter(la => la.assistantId !== assistantId));
      } catch (error) {
        console.error('Failed to delete live assistant:', error);
      }
    }
  };

  const isAssistantLive = (assistantId) => {
    return liveAssistants.some(la => la.assistantId === assistantId);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-muted-foreground">Loading assistants...</div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>VAPI Assistants</CardTitle>
          <Link to="/vapi-assistants/create">
            <Button>Create New Assistant</Button>
          </Link>
          <div className="flex gap-2">
            <Button 
              variant="outline"
              onClick={() => setImportDialogOpen(true)}
            >
              Import from VAPI
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {assistants.map((assistant) => (
                <TableRow key={assistant.id}>
                  <TableCell className="font-medium">{assistant.name}</TableCell>
                  <TableCell>
                    {isAssistantLive(assistant.id) ? (
                      <div className="flex items-center text-green-600">
                        <CheckCircle className="h-4 w-4 mr-2" />
                        Live
                      </div>
                    ) : (
                      <div className="flex items-center text-gray-500">
                        <AlertCircle className="h-4 w-4 mr-2" />
                        Inactive
                      </div>
                    )}
                  </TableCell>
                  <TableCell className="text-right">
                    <div className="flex justify-end gap-2">
                      {!isAssistantLive(assistant.id) && (
                        <>
                          <Link to={`edit/${assistant.id}`}>
                            <Button variant="outline" size="sm">
                              <Edit className="h-4 w-4" />
                            </Button>
                          </Link>
                          <Button 
                            variant="outline" 
                            size="sm"
                            onClick={() => handleDeleteAssistant(assistant.id)}
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleCreateCall(assistant.id)}
                          >
                            <PhoneCall className="h-4 w-4" />
                          </Button>
                        </>
                      )}
                      <Button
                        variant={selectedAssistant?.id === assistant.id ? "default" : "outline"}
                        size="sm"
                        onClick={() => setSelectedAssistant(
                          selectedAssistant?.id === assistant.id ? null : assistant
                        )}
                      >
                        {selectedAssistant?.id === assistant.id ? 'Hide' : 'Select'}
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {selectedAssistant && (
        <Card>
          <CardHeader>
            <CardTitle>Live Assistants - {selectedAssistant.name}</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Assistant ID</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {liveAssistants
                  .filter(la => la.vapiAssistant.id === selectedAssistant.id)
                  .map((liveAssistant) => (
                    <TableRow key={liveAssistant.id}>
                      <TableCell>{liveAssistant.id}</TableCell>
                      <TableCell>{liveAssistant.name}</TableCell>
                      <TableCell className="text-right">
                        <div className="flex justify-end gap-2">
                          <Link to={`/vapi-assistants-live/edit/${liveAssistant.id}`}>
                            <Button variant="outline" size="sm">
                              <Edit className="h-4 w-4" />
                            </Button>
                          </Link>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleDeleteLiveAssistant(liveAssistant.assistantId)}
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}

<Dialog open={importDialogOpen} onOpenChange={setImportDialogOpen}>
        <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Import VAPI Assistant</DialogTitle>
            <DialogDescription>
              Select an assistant to import from your VAPI account
            </DialogDescription>
          </DialogHeader>

          {importError && (
            <Alert variant="destructive">
              <AlertDescription>{importError}</AlertDescription>
            </Alert>
          )}

          {loadingPublished ? (
            <div className="flex items-center justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Model</TableHead>
                  <TableHead>Voice</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {publishedAssistants.map((assistant) => (
                  <TableRow key={assistant.id}>
                    <TableCell>{assistant.name}</TableCell>
                    <TableCell>{assistant.model?.model || 'N/A'}</TableCell>
                    <TableCell>{assistant.voice?.voiceId || 'N/A'}</TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleImport(assistant)}
                        disabled={importingId === assistant.id}
                      >
                        {importingId === assistant.id ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Download className="h-4 w-4" />
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>      
    </div>
  );
};

export default AssistantList;
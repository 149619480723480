// src/services/api.js
import { parseJwt, removeUser, checkTokenExpiration, removeAuthToken } from "../utils/auth";


export const API_BASE_URL = 'https://www.distributewithease.com/api'; //'http://localhost:8080/api'; //
// export const API_BASE_URL = process.env.NODE_ENV === 'production' 
//   ? 'https://www.distributewithease.com/api'
//   : 'http://localhost:8080/api';

//export const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080/api';
const CAPTCHA_ENABLED = process.env.REACT_APP_CAPTCHA_ENABLED === 'true';


export const checkAndHandleTokenExpiration = () => {
  if (!checkTokenExpiration()) {
    // Token is expired - clear storage and redirect to login
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    window.location.href = '/login';
    throw new Error('Session expired. Please login again.');
  }
};

export const loginUser = async (email, password, captchaToken) => {

    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        captchaToken: CAPTCHA_ENABLED ? captchaToken : 'disabled'
    })
      //body: JSON.stringify({ email, password , captchaToken }),
    });
  
    if (!response.ok) {
      throw new Error('Login failed');
    }
  
    return response.json();
};

export const registerUser = async (email, password, captchaToken) => {
  const username = `${email}`;
  const response = await fetch(`${API_BASE_URL}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      email,
      password,
      captchaToken: CAPTCHA_ENABLED ? captchaToken : 'disabled'
  })
  });

  if (!response.ok) {
    throw new Error(response.error);
  }

  return response.json();
};

export const verifyEmail = async (email, code, password) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/verify-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
      email,
      code,
      password}),
    });
    if (!response.ok) {
      throw new Error('Email verification failed');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const resendVerification = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/resend-verification`, {
      method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
    });
    if (!response.ok) {
      throw new Error('Failed to resend verification code');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const getAuthToken = () => {
    return localStorage.getItem('authToken');
};

// Create a reusable headers object
const getJsonHeaders = (authToken) => ({
  'Content-Type': 'application/json;',  // Add trailing semicolon to prevent charset addition
  ...(authToken ? { 'Authorization': `Bearer ${authToken}` } : {})
});


export const getUser = () => {
  return localStorage.getItem('user');
};

// First, let's add the API endpoint to api.js
export const sendContactEmail = async (contactData, captchaToken) => {
  try {
    const response = await fetch(`${API_BASE_URL}/website-contact/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...contactData,
        captchaToken
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const handleAuthorize = async (krupaOrganizationId,organizationId) => {
  try {
      console.log('krupaOrganizationId='+krupaOrganizationId+'organizationId='+organizationId);
      const response = await fetch(`${API_BASE_URL}/google-auth/authorize/${krupaOrganizationId}?clientOrganizationId=${organizationId}`, {
        method: 'GET', // Changed from headers to method
        headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.text(); // Use text() instead of json()
    return data; // This should be the authorization URL
  } catch (error) {
      console.error('Error getting authorization URL:', error);
  }
};

// Function to get organization by logged-in user
export const getOrganizationByUser = async (email) => {
  try {
    checkAndHandleTokenExpiration();

    const apiUrl = `${API_BASE_URL}/organizations/user/${email}`;
    console.log('apiUrl'+apiUrl);
    console.log(localStorage.getItem('authToken'));
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: getJsonHeaders(getAuthToken()),
    });
    console.log('Response status:', response.status);
    if (!response.ok) {
      if (response.status === 401) {
        // Handle unauthorized error
        //removeAuthToken();
        //removeUser();
        //window.location.href = '/login';
        //throw new Error('Session expired');
      }
      throw new Error('Error fetching organization');
    }
    return await response.json();
  } catch (error) {
      // Handle unauthorized error
      //removeAuthToken();
      //removeUser();
      //console.error('Error fetching organization:', error);
      //window.location.href = '/login';
      //throw new Error('Session expired');
  }
};

// Function to save organization
export const saveOrganization = async (organization) => {
  try {
    const userEmail = getUser();
    console.log("saveOrganization Organization="+JSON.stringify(organization));
    console.log("saveOrganization user email"+getUser());
    const response = await fetch(`${API_BASE_URL}/organizations/${userEmail}`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken()),
      body: JSON.stringify({
        name: organization.name,
        logo: organization.logo,
        briefInformation: organization.briefInformation,
        defaultTopUpAmount: organization.defaultTopUpAmount
      }),

    });
    console.log('saveOrganization status'+response.status);
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || 'Error saving organization');
    }
    return await response.json();
  } catch (error) {
    console.error('Error saving organization:', error);
    throw error;
  }
};

export const getVAPIConfigurations = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/vapi-configurations`, {
            headers: getJsonHeaders(getAuthToken()),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching VAPI configurations:', error);
        throw error;
    }
};

export const getVAPIConfiguration = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/vapi-configurations/${id}`,{
        headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching VAPI configuration:', error);
    throw error;
  }
};

export const saveVAPIConfiguration = async (id, config) => {
  try {
    const response = await fetch(`${API_BASE_URL}/vapi-configurations/${id}`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken()),
      body: JSON.stringify(config),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error saving VAPI configuration:', error);
    throw error;
  }
};

export const deleteVAPIConfiguration = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-configurations/${id}`, {
        method: 'DELETE', headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error deleting VAPI configuration:', error);
      throw error;
    }
  };


// Twilio Numbers
export const getTwilioNumbers = async () => {
  const response = await fetch(`${API_BASE_URL}/twilio-numbers`,{
    headers: getJsonHeaders(getAuthToken()),
});
  if (!response.ok) throw new Error('Failed to fetch Twilio numbers');
  return await response.json();
};

export const createTwilioNumber = async (data) => {
  const response = await fetch(`${API_BASE_URL}/twilio-numbers`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to create Twilio number');
  return await response.json();
};

export const deleteTwilioNumber = async (id) => {
  const response = await fetch(`${API_BASE_URL}/twilio-numbers/${id}`, { method: 'DELETE',headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' } });
  if (!response.ok) throw new Error('Failed to delete Twilio number');
  return await response.json();
};

// Subscriptions
export const getSubscriptions = async (organizationId) => {
  const response = await fetch(`${API_BASE_URL}/subscriptions/org/${organizationId}`,{
    method: 'GET',
    headers: getJsonHeaders(getAuthToken()),
});
  if (!response.ok) throw new Error('Failed to fetch subscriptions');
  return await response.json();
};

export const deleteSubscription = async (id) => {
  const response = await fetch(`${API_BASE_URL}/subscriptions/${id}`, { method: 'DELETE', headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' } });
  if (!response.ok) throw new Error('Failed to delete subscription');
  return await response.json();
};

// CRM Integrations
export const getCRMIntegrations = async () => {
  const response = await fetch(`${API_BASE_URL}/crm-integrations`,{
    headers: getJsonHeaders(getAuthToken()),
});
  if (!response.ok) throw new Error('Failed to fetch CRM integrations');
  return await response.json();
};

export const createCRMIntegration = async (data) => {
  const response = await fetch(`${API_BASE_URL}/crm-integrations`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to create CRM integration');
  return await response.json();
};

export const deleteCRMIntegration = async (id) => {
  const response = await fetch(`${API_BASE_URL}/crm-integrations/${id}`, { method: 'DELETE',headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' } });
  if (!response.ok) throw new Error('Failed to delete CRM integration');
  return await response.json();
};

// Calls
export const getCalls = async () => {
  const response = await fetch(`${API_BASE_URL}/calls`,{
    headers: getJsonHeaders(getAuthToken()),
});
  if (!response.ok) throw new Error('Failed to fetch calls');
  return await response.json();
};

export const createCall = async (data) => {
  const response = await fetch(`${API_BASE_URL}/calls`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to create call');
  return await response.json();
};

export const deleteCall = async (id) => {
  const response = await fetch(`${API_BASE_URL}/calls/${id}`, { method: 'DELETE', headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' } });
  if (!response.ok) throw new Error('Failed to delete call');
  return await response.json();
};

// Users (for admin)
/* export const getUsers = async () => {
  const response = await fetch(`${API_BASE_URL}/users`,{
    headers: getJsonHeaders(getAuthToken()),
});
  if (!response.ok) throw new Error('Failed to fetch users');
  return await response.json();
};
 */

export const createUser = async (data) => {
  const response = await fetch(`${API_BASE_URL}/users`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to create user');
  return await response.json();
};

/* export const deleteUser = async (id) => {
  const response = await fetch(`${API_BASE_URL}/users/${id}`, { method: 'DELETE', headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' } });
  if (!response.ok) throw new Error('Failed to delete user');
  return await response.json();
};
 */
export const saveAppointmentSettings = async(data) => {
    console.log('data='+ JSON.stringify(data));
    const response = await fetch(`${API_BASE_URL}/vapi/appointment-settings`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error('Failed to save Appointment Settings');
    return await response.json();
  };

export const getVAPIAssistantsForOrg = async (organizationId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants/org/${organizationId}`, {
        headers: getJsonHeaders(getAuthToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching VAPI assistants:', error);
      throw error;
    }
  };

export const getVAPIAssistant = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants/${id}`, {
        headers: getJsonHeaders(getAuthToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching VAPI assistant:', error);
      throw error;
    }
  };
  
  export const createVAPIAssistant = async (assistant) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants`, {
        method: 'POST',
        headers,
        body: JSON.stringify(assistant),
        credentials: 'include', // Include credentials if using cookies
      });
      if (!response.ok) {
        console.log("create vapi ",JSON.stringify(assistant));
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}\n${errorText}`);
      }
      return await response.json();
    } catch (error) {
      console.log("create vapi ",JSON.stringify(assistant));  
      console.error('Error creating VAPI assistant:', error);
      throw error;
    }
  };

  export const updateVAPIAssistant = async (id, assistant) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants/${id}`, {
        method: 'PUT',
        headers: getJsonHeaders(getAuthToken()),
        body: JSON.stringify(assistant),
        credentials: 'include', // Include credentials
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        if (response.status === 403) {
          throw new Error('Access denied. Please check your authentication.');
        }
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}\n${errorText}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error updating VAPI assistant:', error);
      throw error;
    }
  };

  export const deleteVAPIAssistant = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants/${id}`, {
        method: 'DELETE',
        headers: getJsonHeaders(getAuthToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error deleting VAPI assistant:', error);
      throw error;
    }
  };

  export const createVAPIAssistantCall = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants/${id}/call`, {
        method: 'POST',
        headers: getJsonHeaders(getAuthToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error creating VAPI assistant call:', error);
      throw error;
    }
  };  


export const getAllLiveVAPIAssistants = async (organizationId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants-live/org/${organizationId}`, {
        method: 'GET',
        headers: getJsonHeaders(getAuthToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching live VAPI assistants:', error);
      throw error;
    }
  };
  
  export const getLiveVAPIAssistant = async (assistantId) => {
    try {
      console.log('fetching for assistantId:',assistantId);    
      const response = await fetch(`${API_BASE_URL}/vapi-assistants-live/${assistantId}`, {
        method: 'GET',
        headers: getJsonHeaders(getAuthToken()),
        credentials: 'include', // Include credentials
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching live VAPI assistant:', error);
      throw error;
    }
  };
  
  export const updateLiveVAPIAssistant = async (assistantId, assistant) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants-live/${assistantId}`, {
        method: 'PUT',
        headers: getJsonHeaders(getAuthToken()),
        body: JSON.stringify(assistant),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error updating live VAPI assistant:', error);
      throw error;
    }
  };
  
  export const deleteLiveVAPIAssistant = async (assistantId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vapi-assistants-live/${assistantId}`, {
        method: 'DELETE',
        headers: getJsonHeaders(getAuthToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error deleting live VAPI assistant:', error);
      throw error;
    }
  };

  export const checkWebAvailability = async (organizationId, dateTime, clientTimezone) => {
    try {
      const params = new URLSearchParams({
        organizationId,
        dateTime,
        clientTimezone
    });         
        const response = await fetch(`${API_BASE_URL}/appointments/web/availability?${params}`, {
            method: 'GET',
            headers: getJsonHeaders(getAuthToken()),
        });
        if (!response.ok) {
            throw new Error('Failed to fetch availability');
        }
        return await response.json();
    } catch (error) {
        console.error('Error checking web availability:', error);
        throw error;
    }
};

export const getAppointmentSettings = async (organizationId) => {
  try {
      const response = await fetch(`${API_BASE_URL}/vapi/appointment-settings?organizationId=${organizationId}`, {
          method: 'GET',
          headers: getJsonHeaders(getAuthToken()),
      });
      if (!response.ok) {
          throw new Error('Failed to fetch availability');
      }
      return await response.json();
  } catch (error) {
      console.error('Error checking web availability:', error);
      throw error;
  }
};

export const bookWebAppointment = async (organizationId, dateTime, clientName, clientEmail, clientPhone, clientTimezone, message) => {
  try {
    const params = new URLSearchParams({
      organizationId,
      dateTime,
      clientName,
      clientEmail,
      clientPhone,
      clientTimezone,
      message
  });    
      const response = await fetch(`${API_BASE_URL}/appointments/web/book?${params}`, {
          method: 'POST',
          headers: {
                'Authorization': `Bearer ${getAuthToken()}`,
                'Content-Type': 'application/x-www-form-urlencoded',          },
/*           body: JSON.stringify({
              organizationId,
              dateTime,
              clientName,
              clientEmail
          }),
 */      });
      if (!response.ok) {
          throw new Error(`Failed to book appointment: ${response.status} ${response.statusText}`);
      }
      const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return await response.json();
        } else {
            return await response.text();
        }
  } catch (error) {
      console.error('Error booking web appointment:', error);
      throw error;
 }
}; 

export const getGoogleAuthInfo = async (organizationId) => {
  try {
      const response = await fetch(`${API_BASE_URL}/google-auth/info/${organizationId}`, {
          headers: getJsonHeaders(getAuthToken()),
      });
      if (!response.ok) {
          throw new Error('Failed to fetch Google auth info');
      }
      return await response.json();
  } catch (error) {
      console.error('Error fetching Google auth info:', error);
      throw error;
  }
};

export const revokeGoogleAuth = async (organizationId) => {
  try {
      const response = await fetch(`${API_BASE_URL}/google-auth/revoke/${organizationId}`, {
          method: 'POST',
          headers: getJsonHeaders(getAuthToken()),
      });
      
      if (!response.ok) {
          throw new Error('Failed to revoke Google auth');
      }
      
      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error revoking Google auth:', error);
      throw error;
  }
};

export const getOrganizationIdFromAPI = async() => {
  return 'krupa9844';
};

export const getSubscriptionStartDate = async (organizationId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/subscriptions/${organizationId}/start-date`, {
      method: 'GET',
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch subscription start date');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching subscription start date:', error);
    throw error;
  }
};

export const fetchCallRecords = async (organizationId, { month, year, assistantId, page, size }) => {
  try {
    const queryParams = new URLSearchParams({
      month: month || '',
      year: year || '',
      assistantId: assistantId || '',
      page: page.toString(),
      size: size.toString()
    }).toString();

    const response = await fetch(`${API_BASE_URL}/call-tracks/${organizationId}?${queryParams}`, {
      method: 'GET',
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch call records');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching call records:', error);
    throw error;
  }
};

export const getMonthlyBillings = async (organizationId, page = 1, size = 10, startDate = null, endDate = null) => {
  let url = `${API_BASE_URL}/billing/monthly/${organizationId}?page=${page}&size=${size}`;
  if (startDate && endDate) {
    url += `&startDate=${startDate}&endDate=${endDate}`;
  }
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch monthly billings');
  }
  return await response.json();
};

export const getCurrentMonthlyBilling = async (organizationId) => {
  try {
  const response = await fetch(`${API_BASE_URL}/billing/monthly/${organizationId}/current`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch current monthly billing');
  }
  return await response.json();
} catch (error) {
  console.error('Error fetching current monthly billing:', error);
  throw error;
}  
};

export const getMonthlyBillingSummary = async (organizationId, startDate, endDate) => {
  const response = await fetch(`${API_BASE_URL}/billing/monthly/${organizationId}/summary?startDate=${startDate}&endDate=${endDate}`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch monthly billing summary');
  }
  return await response.json();
};


const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'An error occurred');
  }
  return response.json();
};

export const createStripeCustomer = async (email, paymentMethodId) => {
  const response = await fetch(`${API_BASE_URL}/payments/create-customer`, {
    method: 'POST',
    headers: getJsonHeaders(getAuthToken()),
    body: JSON.stringify({ email, paymentMethodId }),
  });
  return handleResponse(response);
};

/* export const createSubscription = async (data) => {
  const response = await fetch(`${API_BASE_URL}/subscriptions`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${getAuthToken()}`,'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to create subscription');
  return await response.json();
};
 */
export const PRODUCTS = [
  {
    id: 'basic',
    name: 'Basic Plan Monthly',
    priceId: process.env.REACT_APP_BASIC_PRICE_ID,
    description: 'Basic Plan for small businesses',
    price: '$509.99/month',
    billingPeriod: 'monthly'
  },
  {
      id: 'basic',
      name: 'Basic Plan Yearly',
      priceId: process.env.REACT_APP_BASIC_Y_PRICE_ID,
      description: 'Basic Plan for small businesses',
      price: '$509.99/year',
      billingPeriod: 'yearly'
  },  
  {
    id: 'premium',
    name: 'Premium Plan Monthly',
    priceId: process.env.REACT_APP_PRO_PRICE_ID,
    description: 'Advanced Plan for growing businesses',
    price: '$1099.99/month',
    billingPeriod: 'monthly'
  },
  {
      id: 'premium',
      name: 'Premium Plan Yearly',
      priceId: process.env.REACT_APP_PRO_Y_PRICE_ID,
      description: 'Premium Plan for growing businesses',
      price: '$1099.99/year',
      billingPeriod: 'yearly'
  },
  {
      id: 'ultimate',
      name: 'Ultimate Plan Monthly',
      priceId: process.env.REACT_APP_ULTIMATE_PRICE_ID,
      description: 'Ultimate Plan for growing businesses',
      price: '$1599.99/month',
      billingPeriod: 'monthly'
    },
    {
      id: 'ultimate',
      name: 'Ultimate Plan Yearly',
      priceId: process.env.REACT_APP_ULTIMATE_Y_PRICE_ID,
      description: 'Ultimate Plan for growing businesses',
      price: '$17100.99/year',
      billingPeriod: 'yearly'
    }
];


export const saveSubscriptionFromPlan = async (organization) => {
  try {
    const selectedPlan = JSON.parse(localStorage.getItem('selectedPlan'));
    const matchingProduct = PRODUCTS.find(
      product => product.id === selectedPlan.type && 
                 product.billingPeriod === selectedPlan.billing
    );

    if (!matchingProduct) {
      throw new Error('Selected plan not found');
    }

    // Remove the '$' and '/month' or '/year' from the price string
    const priceString = matchingProduct.price.replace('$', '').split('/')[0];

    // Format date as YYYY-MM-DD
    const startDate = new Date(selectedPlan.startDate);
    const formattedStartDate = startDate.toISOString().split('T')[0]; // Gets just the date part

    
    // Define covered call amounts based on plan type
    const coveredCallAmounts = {
      basic: "300.00",
      premium: "600.00",
      ultimate: "1000.00"
    };

    // Get covered call amount based on plan type, default to 300 if not found
    const coveredCallAmount = coveredCallAmounts[selectedPlan.type.toLowerCase()] || 300;

    
    // Combine plan details with product details
    const subscriptionData = {
      subscriptionPlan: selectedPlan.type.toUpperCase(),
      subscriptionFreq: selectedPlan.billing.toUpperCase(),
      subscriptionAmount: parseFloat(priceString),
      isActive: false,
      startDate: formattedStartDate,
      priceId: selectedPlan.priceId, // Use the stored priceId
      productName: selectedPlan.productName,
      description: selectedPlan.description,
      coveredCallAmount: coveredCallAmount,
      endDate: null,
      paymentStatus: "PENDING",
      lastPaymentStatus: "PENDING"
    };

    const response = await fetch(`${API_BASE_URL}/subscriptions/${organization.id}`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken()),
      body: JSON.stringify(subscriptionData),
    });

    if (!response.ok) {
      if (response.status === 403) {
        throw new Error('Authorization error - please check your permissions');
      }
      const errorData = await response.json();
      console.error('Subscription error:', errorData);  // Add this line
      throw new Error(errorData.message || 'Failed to save subscription');
    }

    return await response.json();
  } catch (error) {
    console.error('Error saving subscription:', error);
    throw error;
  }
};

export const createSubscription = async (customerId, priceId, frequency, organizationId) => {
  console.log("createSubscription Auth token:", getAuthToken());
  const response = await fetch(`${API_BASE_URL}/payments/create-subscription`, {
    method: 'POST',
    headers: getJsonHeaders(getAuthToken()),
    body: JSON.stringify({ customerId, priceId, frequency, organizationId }),
  });
  return handleResponse(response);
};

export const cancelSubscription = async (id, cancellationReason) => {
  const response = await fetch(`${API_BASE_URL}/subscriptions/${id}/cancel`, {
    method: 'POST',
    headers: getJsonHeaders(getAuthToken()),
    body: JSON.stringify({ cancellationReason })
  });
  if (!response.ok) throw new Error('Failed to cancel subscription');
  return await response.json();
};

export const createPaymentIntent = async (amount, currency, customerId, organizationId) => {
  const response = await fetch(`${API_BASE_URL}/payments/create-payment-intent`, {
    method: 'POST',
    headers: getJsonHeaders(getAuthToken()),
    body: JSON.stringify({ amount, currency, customerId, organizationId }),
  });
  return handleResponse(response);
};

export const confirmPayment = async (paymentIntentId) => {
  const response = await fetch(`${API_BASE_URL}/payments/confirm-payment`, {
    method: 'POST',
    headers: getJsonHeaders(getAuthToken()),
    body: JSON.stringify({ paymentIntentId }),
  });
  return handleResponse(response);
};

export const getPaymentHistory = async (organizationId) => {
  const response = await fetch(`${API_BASE_URL}/payments/history/${organizationId}`, {
    method: 'GET',
    headers: getJsonHeaders(getAuthToken()),
  });
  return handleResponse(response);
};

export const getUpcomingPayments = async (organizationId) => {
  const response = await fetch(`${API_BASE_URL}/payments/upcoming/${organizationId}`, {
    method: 'GET',
    headers: getJsonHeaders(getAuthToken()),
  });
  const resp = await response.json();
  if (!response.ok) {
    
    if (response.status == 400) {
        return '{"error": "You have no upcoming payments"}';
    } else {
        throw new Error(resp.error || 'An error occurred');
    }
  }
  return resp;

};

export const createCheckoutSession = async (organizationId, priceId) => {
  console.log(JSON.stringify({ organizationId, priceId }));
  const response = await fetch(`${API_BASE_URL}/payments/create-checkout-session`, {
    method: 'POST',
    headers: getJsonHeaders(getAuthToken()),
    body: JSON.stringify({ organizationId, priceId }),
  });
  return handleResponse(response);
};

// Add these new API endpoints alongside your existing ones in api.js

export const getCallSummariesByOrganization = async (organizationId, { month, year, assistantId, page, size }) => {
  try {
    const queryParams = new URLSearchParams({
      month: month || '',
      year: year || '',
      assistantId: assistantId || '',
      page: page.toString(),
      size: size.toString()
    }).toString();

    const response = await fetch(
      `${API_BASE_URL}/callsummary/${organizationId}?${queryParams}`, 
      {
        method: 'GET',
        headers: getJsonHeaders(getAuthToken()),
      }
    );

    if (!response.ok) {
      throw new Error(response.status === 400 ? 'Invalid request parameters' : 'Failed to fetch call summaries');
    }

    const data = await response.json();
    //console.log('getCallSummariesByOrganization data'+await data);
    // Transform date strings to Date objects
    // return data.content.map(summary => ({
    //   ...summary,
    //   callTrack: {
    //     ...summary.callTrack,
    //     startTime: new Date(summary.callTrack.startTime),
    //     endTime: new Date(summary.callTrack.endTime)
    //   },
    //   createdAt: new Date(summary.createdAt),
    //   updatedAt: new Date(summary.updatedAt),
    //   content: data.content || [],
    //   totalPages: data.totalPages || 1,
    //   totalElements: data.totalElements || 0,
    //   size: data.size || 10,
    //   number: data.number || 0
    // }));
    return {
      content: data.content || [],
      totalPages: data.totalPages || 1,
      totalElements: data.totalElements || 0,
      size: data.size || 10,
      number: data.number || 0
    };
  } catch (error) {
    console.error('Error fetching call summaries:', error);
    throw error;
  }
};

export const getCallSummaryById = async (summaryId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/callsummary/${summaryId}`, {
      headers: getJsonHeaders(getAuthToken()),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch call summary');
    }

    const data = await response.json();
    return {
      ...data,
      callTrack: {
        ...data.callTrack,
        startTime: new Date(data.callTrack.startTime),
        endTime: new Date(data.callTrack.endTime)
      },
      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  } catch (error) {
    console.error('Error fetching call summary:', error);
    throw error;
  }
};

export const getCallSummaryByCallId = async (callId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/callsummary/call/${callId}`, {
      headers: getJsonHeaders(getAuthToken()),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch call summary');
    }

    const data = await response.json();
    return {
      ...data,
      callTrack: {
        ...data.callTrack,
        startTime: new Date(data.callTrack.startTime),
        endTime: new Date(data.callTrack.endTime)
      },
      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt)
    };
  } catch (error) {
    console.error('Error fetching call summary:', error);
    throw error;
  }
};

export const getCallSummariesByAssistant = async (assistantId, page = 0, size = 10) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/callsummary/assistant/${assistantId}?page=${page}&size=${size}`, 
      {
        headers: getJsonHeaders(getAuthToken()),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch call summaries');
    }

    const data = await response.json();
    return data.content.map(summary => ({
      ...summary,
      callTrack: {
        ...summary.callTrack,
        startTime: new Date(summary.callTrack.startTime),
        endTime: new Date(summary.callTrack.endTime)
      },
      createdAt: new Date(summary.createdAt),
      updatedAt: new Date(summary.updatedAt)
    }));
  } catch (error) {
    console.error('Error fetching call summaries:', error);
    throw error;
  }
};

export const deleteCallSummary = async (summaryId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/callsummary/${summaryId}`, {
      method: 'DELETE',
      headers: getJsonHeaders(getAuthToken()),
    });

    if (!response.ok) {
      throw new Error('Failed to delete call summary');
    }
  } catch (error) {
    console.error('Error deleting call summary:', error);
    throw error;
  }
};

// Export function to download recording
export const downloadRecording = async (recordingUrl) => {
  try {
    const response = await fetch(recordingUrl, {
      headers: {
        'Authorization': `Bearer ${getAuthToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to download recording');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'call-recording.wav';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading recording:', error);
    throw error;
  }
};

// Function to format duration for display
export const formatCallDuration = (minutes) => {
  const hrs = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.floor((minutes * 60) % 60);
  
  return {
    hours: hrs,
    minutes: mins,
    seconds: secs,
    formatted: `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  };
};

// Function to format cost for display
export const formatCallCost = (cost) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(cost);
};

export const getAppointmentById = async (appointmentId) => {
  try {
      const response = await fetch(`${API_BASE_URL}/appointments/${appointmentId}`, {
          headers: getJsonHeaders(getAuthToken()),
      });
      
      if (response.status === 404) {
          return null;
      }
      
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
  } catch (error) {
      console.error('Error fetching appointment:', error);
      throw new Error('Failed to fetch appointment details');
  }
};

export const cancelAppointmentById = async (appointmentId,message) => {
  try {
    const params = new URLSearchParams({
      message: message || ''
    });  

    const response = await fetch(`${API_BASE_URL}/appointments/web/cancel/${appointmentId}?${params}`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) {
      throw new Error('Failed to cancel appointment');
    }
    return await response.json();
  } catch (error) {
    console.error('Error cancelling appointment:', error);
    throw error;
  }
};

export const rescheduleAppointmentById = async (appointmentId, newDateTime, timezone, message) => {
  try {
    const params = new URLSearchParams({
      timezone: timezone || '',
      message: message || ''
    }).toString();
    const encodedDateTime = encodeURIComponent(newDateTime);
    const response = await fetch(
      `${API_BASE_URL}/appointments/web/reschedule/${appointmentId}?${params}`, 
      {
        method: 'POST',
        headers: getJsonHeaders(getAuthToken()),
        body: JSON.stringify({
          newDateTime: newDateTime
        })
      }
    );
    const data = await response.json();
    console.log('Reschedule response:', data);

    if (!response.ok) {
      throw new Error('Failed to reschedule appointment');
    }
    return data;    
  } catch (error) {
    console.error('Error rescheduling appointment:', error);
    throw error;
  }
};

// Phone Numbers API
export const getPhoneNumbers = async (organizationId) => {
  try {
      console.log('getPhoneNumbers organizationId='+organizationId);
      const response = await fetch(`${API_BASE_URL}/vapi/phone-numbers?organizationId=${organizationId}`, {
          method: 'GET',
          headers: getJsonHeaders(getAuthToken()),
      });
      console.log('getPhoneNumbers  response='+response);
      return handleResponse(response);
  } catch (error) {
      throw new Error(`Failed to fetch phone numbers: ${error.message}`);
  }
};

export const importPhoneNumber = async (requestData, organizationId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/vapi/phone-numbers/import?organizationId=${organizationId}`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken()),
      body: JSON.stringify(requestData)
    });
    return handleResponse(response);
  } catch (error) {
      throw new Error(`Failed to import phone number: ${error.message}`);
  }
};

// Inbound Calls API
export const getInboundCalls = async (organizationId) => {
  try {
      const response = await fetch(`${API_BASE_URL}/vapi/inbound-calls?organizationId=${organizationId}`, {
          method: 'GET',
          headers: getJsonHeaders(getAuthToken()),
      });
      return handleResponse(response);
  } catch (error) {
      throw new Error(`Failed to fetch inbound calls: ${error.message}`);
  }
};

export const createInboundCall = async (assistantId, phoneNumberId, name, organizationId) => {
  try {
      const response = await fetch(`${API_BASE_URL}/vapi/inbound-calls`, {
          method: 'POST',
          headers: getJsonHeaders(getAuthToken()),
          body: JSON.stringify({
              assistantId,
              phoneNumberId,
              name,
              organizationId
          })
      });
      return handleResponse(response);
  } catch (error) {
      throw new Error(`Failed to create inbound call: ${error.message}`);
  }
};

// Assistant API
export const getAssistants = async (organizationId) => {
  try {
      console.log('getAssistants organizationId='+organizationId);
      const response = await fetch(`${API_BASE_URL}/vapi-assistants-live/org/${organizationId}`, {
          method: 'GET',
          headers: getJsonHeaders(getAuthToken()),
      });
      return handleResponse(response);
  } catch (error) {
      throw new Error(`Failed to fetch assistants: ${error.message}`);
  }
};

export const getPublishedVAPIAssistants = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/vapi-assistants/published`, {
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch published VAPI assistants');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching published VAPI assistants:', error);
    throw error;
  }
};

// Initialize CSRF token for security (if using Spring Security)
export const initializeCsrf = () => {
  const csrfToken = document.querySelector('meta[name="_csrf"]')?.getAttribute('content');
  const csrfHeader = document.querySelector('meta[name="_csrf_header"]')?.getAttribute('content');
  
  if (csrfToken && csrfHeader) {
      return {
          [csrfHeader]: csrfToken
      };
  }
  return {};
};

// Add CSRF headers to all fetch requests

// const headers = {
//   'Content-Type': 'application/json',
//   ...initializeCsrf()
// };

const headers = {
  'Content-Type': 'application/json', // Remove charset=UTF-8
  'Authorization': `Bearer ${getAuthToken()}`
};

// File Upload API
export const uploadFile = async (file, organizationId) => {
  try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('organizationId', organizationId);

      const response = await fetch(`${API_BASE_URL}/vapi/files`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${getAuthToken()}`,
            // Do not set Content-Type here, let the browser set it        }
            'Accept': 'application/json'
          },
          body: formData
        });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `Upload failed: ${response.statusText}`);
      }

      return await response.json();
  } catch (error) {
      throw new Error(`Failed to upload file: ${error.message}`);
  }
};

export const getUploadedFiles = async (organizationId) => {
  try {
      const response = await fetch(`${API_BASE_URL}/vapi/files?organizationId=${organizationId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`,
          // Do not set Content-Type here, let the browser set it        }
          'Accept': 'application/json'
        }
  });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `Failed to fetch files: ${response.statusText}`);
      }

      return await response.json();
  } catch (error) {
      throw new Error(`Failed to fetch files: ${error.message}`);
  }
};

// api.js
export const createOutboundCall = async (callData, organizationId) => {
  try {
      const response = await fetch(`${API_BASE_URL}/vapi/outbound-calls`, {
          method: 'POST',
          headers: getJsonHeaders(getAuthToken()),
          body: JSON.stringify({
            ...callData,
            organizationId
          })
      });

      if (!response.ok) {
          const errorData = await response.json().catch(() => ({ message: response.statusText }));
          throw new Error(errorData.message || `Call initiation failed: ${response.statusText}`);
      }

      return await response.json();
  } catch (error) {
      console.error('Outbound call error:', error);
      throw new Error(`Failed to initiate call: ${error.message}`);
  }
};

// Add these functions to api.js

export const getContacts = async (organizationId, page = 0, size = 10) => {
  try {
    const response = await fetch(`${API_BASE_URL}/contacts/org/${organizationId}?page=${page}&size=${size}`, {
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) throw new Error('Failed to fetch contacts');
    return await response.json();
  } catch (error) {
    console.error('Error fetching contacts:', error);
    throw error;
  }
};

export const searchContacts = async (organizationId, searchParams) => {
  try {
    const params = new URLSearchParams({
      organizationId,
      page: searchParams.page || 0,
      size: searchParams.size || 10,
      ...(searchParams.email && { email: searchParams.email }),
      ...(searchParams.phone && { phone: searchParams.phone })
    });

    const response = await fetch(`${API_BASE_URL}/contacts/search?${params}`, {
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) throw new Error('Failed to search contacts');
    return await response.json();
  } catch (error) {
    console.error('Error searching contacts:', error);
    throw error;
  }
};

export const createContact = async (organizationId, contactData) => {
  try {
    const params = new URLSearchParams({
      clientName: contactData.clientName,
      clientEmail: contactData.clientEmail,
      clientPhone: contactData.clientPhone,
      clientTimezone: contactData.clientTimezone
    });

    const response = await fetch(`${API_BASE_URL}/contacts/org/${organizationId}?${params}`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) throw new Error('Failed to create contact');
    return await response.json();
  } catch (error) {
    console.error('Error creating contact:', error);
    throw error;
  }
};

export const deleteContact = async (contactId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/contacts/${contactId}`, {
      method: 'DELETE',
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) throw new Error('Failed to delete contact');
    return await response.json();
  } catch (error) {
    console.error('Error deleting contact:', error);
    throw error;
  }
};

// Add these functions to api.js

export const importContacts = async (organizationId, formData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/contacts/import/${organizationId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getAuthToken()}`,
        // Don't set Content-Type header when sending FormData
      },
      body: formData
    });
    if (!response.ok) throw new Error('Failed to import contacts');
    return await response.json();
  } catch (error) {
    console.error('Error importing contacts:', error);
    throw error;
  }
};

export const updateOptOutPreferences = async (contactId, preferences) => {
  try {
    console.log('Sending preferences update:', { contactId, preferences });
    const response = await fetch(`${API_BASE_URL}/contacts/${contactId}/opt-out`, {
      method: 'PUT',
      headers: getJsonHeaders(getAuthToken()),
      body: JSON.stringify(preferences)
    });
    
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || 'Failed to update opt-out preferences');
    }
    const data = await response.json();
    console.log('Received updated contact:', data);
    return data;
  } catch (error) {
    console.error('Error updating opt-out preferences:', error);
    throw error;
  }
};

export const toggleCampaignSelection = async (contactId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/contacts/${contactId}/toggle-campaign`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken())
    });
    if (!response.ok) throw new Error('Failed to toggle campaign selection');
    return await response.json();
  } catch (error) {
    console.error('Error toggling campaign selection:', error);
    throw error;
  }
};

//Add these functions to your existing api.js file

// SMS Conversations
export const getSMSConversations = async (organizationId, page = 0, size = 10) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/sms/conversations?organizationId=${organizationId}&page=${page}&size=${size}`, 
      {
        headers: getJsonHeaders(getAuthToken()),
      }
    );
    if (!response.ok) throw new Error('Failed to fetch SMS conversations');
    return await response.json();
  } catch (error) {
    console.error('Error fetching SMS conversations:', error);
    throw error;
  }
};

export const getSMSConversationById = async (conversationId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/sms/conversations/${conversationId}`, {
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) throw new Error('Failed to fetch SMS conversation');
    return await response.json();
  } catch (error) {
    console.error('Error fetching SMS conversation:', error);
    throw error;
  }
};

export const getSMSMessages = async (conversationId, page = 0, size = 50) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/sms/conversations/${conversationId}/messages?page=${page}&size=${size}`, 
      {
        headers: getJsonHeaders(getAuthToken()),
      }
    );
    if (!response.ok) throw new Error('Failed to fetch SMS messages');
    return await response.json();
  } catch (error) {
    console.error('Error fetching SMS messages:', error);
    throw error;
  }
};

export const sendSMSMessage = async ({
  to,
  message,
  conversationId,
  organizationId
}) => {
  try {
    const response = await fetch(`${API_BASE_URL}/sms/send`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken()),
      body: JSON.stringify({
        to,
        message,
        conversationId,
        organizationId
      })
    });
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || 'Failed to send SMS message');
    }
    return await response.json();
  } catch (error) {
    console.error('Error sending SMS message:', error);
    throw error;
  }
};

export const searchSMSConversations = async (organizationId, searchParams) => {
  try {
    const queryParams = new URLSearchParams({
      organizationId,
      page: searchParams.page || 0,
      size: searchParams.size || 10,
      ...(searchParams.phone && { phone: searchParams.phone }),
      ...(searchParams.name && { name: searchParams.name }),
      ...(searchParams.fromDate && { fromDate: searchParams.fromDate }),
      ...(searchParams.toDate && { toDate: searchParams.toDate })
    });

    const response = await fetch(
      `${API_BASE_URL}/sms/conversations/search?${queryParams}`,
      {
        headers: getJsonHeaders(getAuthToken()),
      }
    );
    if (!response.ok) throw new Error('Failed to search SMS conversations');
    return await response.json();
  } catch (error) {
    console.error('Error searching SMS conversations:', error);
    throw error;
  }
};

export const getActiveSMSConversations = async (organizationId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/sms/conversations/active?organizationId=${organizationId}`,
      {
        headers: getJsonHeaders(getAuthToken()),
      }
    );
    if (!response.ok) throw new Error('Failed to fetch active SMS conversations');
    return await response.json();
  } catch (error) {
    console.error('Error fetching active SMS conversations:', error);
    throw error;
  }
};

export const getFailedSMSMessages = async (organizationId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/sms/messages/failed?organizationId=${organizationId}`,
      {
        headers: getJsonHeaders(getAuthToken()),
      }
    );
    if (!response.ok) throw new Error('Failed to fetch failed SMS messages');
    return await response.json();
  } catch (error) {
    console.error('Error fetching failed SMS messages:', error);
    throw error;
  }
};

export const retrySMSMessage = async (messageId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/sms/messages/${messageId}/retry`, {
      method: 'POST',
      headers: getJsonHeaders(getAuthToken()),
    });
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || 'Failed to retry SMS message');
    }
    return await response.json();
  } catch (error) {
    console.error('Error retrying SMS message:', error);
    throw error;
  }
};

export const getSMSMessageStats = async (organizationId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/sms/messages/stats?organizationId=${organizationId}`,
      {
        headers: getJsonHeaders(getAuthToken()),
      }
    );
    if (!response.ok) throw new Error('Failed to fetch SMS message statistics');
    return await response.json();
  } catch (error) {
    console.error('Error fetching SMS message statistics:', error);
    throw error;
  }
};

export const getPricingPlans = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/pricing/plans`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit' // Don't send credentials for public endpoint //mode: 'cors'
    });
    console.log('Response status:', response.status);
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`Failed to fetch pricing plans: ${response.status}`);
    }
    const data = await response.json();
    console.log('Received data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching pricing plans:', error);
    throw error;
  }
};

export const getPricingPlanByType = async (planType) => {
  try {
    const response = await fetch(`${API_BASE_URL}/pricing/plans/${planType}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit' // Don't send credentials for public endpoint //mode: 'cors'
    });
    if (!response.ok) {
      throw new Error('Failed to fetch pricing plan');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching pricing plan:', error);
    throw error;
  }
};
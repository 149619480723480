import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format, addDays, isAfter, isBefore, isToday, getDay } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { getAppointmentById, checkWebAvailability, rescheduleAppointmentById } from '../services/api';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import { Calendar } from "./ui/calendar";
import { ScrollArea } from "./ui/scroll-area";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import {
  Alert,
  AlertDescription,
} from "./ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import ThankYou from './ThankYou';

const TIMEZONES = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'Europe/London',
  'Europe/Paris',
  'Asia/Tokyo',
  'Australia/Sydney',
];

const RescheduleAppointment = () => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [weekendDays, setWeekendDays] = useState([]);
  const [clientTimezone, setClientTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [message, setMessage] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [bookedAppointment, setBookedAppointment] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    loadAppointmentDetails();
  }, [appointmentId]);

  useEffect(() => {
    if (selectedDate && appointment) {
      fetchTimeSlots();
    }
  }, [selectedDate, appointment, clientTimezone]);

  const loadAppointmentDetails = async () => {
    try {
      const data = await getAppointmentById(appointmentId);
      setAppointment(data);
      setSelectedDate(new Date(data.appointmentDateTime));
      setClientTimezone(data.timezone);
      setMessage(data.message || '');
      setWeekendDays(data.organization.weekendDays || [0, 6]);
    } catch (error) {
      navigate('/appointment-setter');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTimeSlots = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const dateString = selectedDate.toISOString().split('T')[0] + 'T00:00:00Z';
      
      const slots = await checkWebAvailability(
        appointment.organization.id,
        dateString,
        clientTimezone
      );

      setTimeSlots(slots.map(slot => ({
        ...slot,
        dateTime: new Date(slot.dateTime)
      })));
      setSelectedTime(null);
    } catch (error) {
      setError('Failed to fetch available time slots');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimezoneChange = async (timezone) => {
    setClientTimezone(timezone);
    await fetchTimeSlots();
  };

  const handleReschedule = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const hours = selectedTime.getHours();
      const minutes = selectedTime.getMinutes();
      const dateString = `${format(selectedTime, 'yyyy-MM-dd')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
      const utcDate = fromZonedTime(dateString, clientTimezone);

      const response = await rescheduleAppointmentById(
        appointmentId,
        utcDate.toISOString(),
        clientTimezone,
        message
      );

      if (response) {
        const appointmentData = {
          ...appointment,
          appointmentDateTime: selectedTime.toISOString(),
          timezone: clientTimezone,
          message: message,
          contact: {
            ...appointment.contact
          }
        };
        setBookedAppointment(appointmentData);
        setShowThankYou(true);
      } else {
        setError('Failed to reschedule appointment');
      }
    } catch (error) {
      setError('An error occurred while rescheduling');
    } finally {
      setIsLoading(false);
      setShowConfirmDialog(false);
    }
  };

  const isDateEnabled = (date) => {
    const today = new Date();
    const maxDate = addDays(today, appointment?.organization?.bookingOpenNoDays || 30);
    const isWeekend = weekendDays.includes(getDay(date));
    return isAfter(date, today) && isBefore(date, maxDate) && !isWeekend;
  };

  if (showThankYou && bookedAppointment) {
    return <ThankYou type="rescheduled" appointment={bookedAppointment} />;
  }

  if (isLoading && !appointment) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-muted-foreground">Loading...</div>
      </div>
    );
  }

  if (error && !appointment) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  if (!appointment) {
    return (
      <Alert>
        <AlertDescription>Appointment not found</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="container max-w-4xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle>Reschedule Appointment</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-medium">Current Appointment Details</h3>
              <div className="mt-2 space-y-2">
                <p>Date: {format(new Date(appointment.appointmentDateTime), 'PPPP')}</p>
                <p>Time: {format(new Date(appointment.appointmentDateTime), 'h:mm a')}</p>
                <p>Contact: {appointment.contact.name}</p>
                <p>Email: {appointment.contact.email}</p>
              </div>
            </div>

            <div>
              <Label htmlFor="timezone">Timezone</Label>
              <Select
                value={clientTimezone}
                onValueChange={handleTimezoneChange}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select timezone" />
                </SelectTrigger>
                <SelectContent>
                  {TIMEZONES.map(tz => (
                    <SelectItem key={tz} value={tz}>
                      {tz}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div>
              <Label htmlFor="message">Message (Optional)</Label>
              <Textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Add any additional notes or requests"
                rows={3}
              />
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-medium">Select New Date and Time</h3>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <Label>Select Date</Label>
                <Calendar
                  mode="single"
                  selected={selectedDate}
                  onSelect={setSelectedDate}
                  disabled={date => !isDateEnabled(date)}
                  className="rounded-md border"
                />
              </div>

              <div>
                <Label>Select Time</Label>
                <ScrollArea className="h-[300px] rounded-md border p-4">
                  <div className="grid grid-cols-2 gap-2">
                    {isLoading ? (
                      <div>Loading time slots...</div>
                    ) : timeSlots.length === 0 ? (
                      <div>No available time slots</div>
                    ) : (
                      timeSlots
                        .filter(slot => slot.available)
                        .map((slot) => (
                          <Button
                            key={slot.dateTime.toISOString()}
                            variant={selectedTime?.toISOString() === slot.dateTime.toISOString() ? "default" : "outline"}
                            onClick={() => setSelectedTime(slot.dateTime)}
                            className="w-full"
                          >
                            {format(slot.dateTime, 'h:mm a')}
                          </Button>
                        ))
                    )}
                  </div>
                </ScrollArea>
              </div>
            </div>
          </div>

          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <Button 
            className="w-full"
            onClick={() => setShowConfirmDialog(true)}
            disabled={!selectedTime || isLoading}
          >
            {isLoading ? "Rescheduling..." : "Reschedule Appointment"}
          </Button>
        </CardContent>
      </Card>

      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Reschedule</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to reschedule this appointment? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleReschedule}>
              Confirm Reschedule
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default RescheduleAppointment;
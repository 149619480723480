import React, { useEffect, useState, useContext } from 'react';
import { handleAuthorize, getGoogleAuthInfo, revokeGoogleAuth, getOrganizationIdFromAPI } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Button } from "./ui/button";
import { Alert, AlertDescription } from "./ui/alert";
import { Calendar, LogOut, Mail, Loader2 } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";

const GoogleAuthComponent = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [krupaOrganizationId, setKrupaOrganizationId] = useState(null);
  const [authUrl, setAuthUrl] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [associatedEmail, setAssociatedEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showRevokeDialog, setShowRevokeDialog] = useState(false);

  useEffect(() => {
    const fetchOrganizationId = async () => {
      try {
        const id = await getOrganizationIdFromAPI();
        setKrupaOrganizationId(id);
      } catch (error) {
        setError('Failed to fetch organization ID');
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationId();
  }, []);

  useEffect(() => {
    if (krupaOrganizationId) {
      checkAuthStatus();
    }
  }, [krupaOrganizationId]);

  const checkAuthStatus = async () => {
    if (!krupaOrganizationId) return;

    try {
      const authInfo = await getGoogleAuthInfo(organizationId);
      setIsAuthorized(authInfo.isAuthorized);
      setAssociatedEmail(authInfo.email);
    } catch (error) {
      setError('Failed to check authorization status');
    }
  };

    const handleAuthorizeGoogle = async () => {
        if (!krupaOrganizationId) {
        setError('Organization ID is not available');
        return;
        }

        try {
        const url = await handleAuthorize(krupaOrganizationId, organizationId);
        setAuthUrl(url);
        const authWindow = window.open(url, 'Google Authorization', 'width=600,height=600');

        const checkInterval = setInterval(async () => {
            const authInfo = await getGoogleAuthInfo(organizationId);
            if (authInfo.isAuthorized) {
            clearInterval(checkInterval);
            setIsAuthorized(true);
            setAssociatedEmail(authInfo.email);
            }
        }, 2000);

        setTimeout(() => clearInterval(checkInterval), 120000);
        } catch (error) {
        setError('Failed to get authorization URL');
        }
    };

    const handleUnauthorizeGoogle = async () => {
        if (!krupaOrganizationId) {
            setError('Organization ID is not available');
            return;
        }

        try {
            const response = await revokeGoogleAuth(krupaOrganizationId);
            setIsAuthorized(false);
            setAssociatedEmail('');
            setShowRevokeDialog(false);
        } catch (error) {
            setError('Failed to revoke Google authorization');
        }
    };


  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container max-w-2xl mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Calendar className="h-6 w-6" />
            Google Calendar Authorization
          </CardTitle>
          <CardDescription>
            Connect your Google Calendar to manage appointments
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {isAuthorized ? (
            <div className="space-y-4">
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Mail className="h-4 w-4" />
                Authorized with Gmail: 
                <span className="font-medium text-foreground">
                  {associatedEmail}
                </span>
              </div>
              
              <Button
                variant="destructive"
                onClick={() => setShowRevokeDialog(true)}
                className="w-full"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Unauthorize Google Calendar
              </Button>
            </div>
          ) : (
            <div className="space-y-4">
              <Button 
                onClick={handleAuthorizeGoogle}
                className="w-full"
              >
                <Calendar className="mr-2 h-4 w-4" />
                Authorize Google Calendar
              </Button>

              {authUrl && (
                <p className="text-sm text-muted-foreground">
                  If the authorization window didn't open, please{' '}
                  <a 
                    href={authUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:underline"
                  >
                    click here
                  </a>
                  .
                </p>
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <AlertDialog open={showRevokeDialog} onOpenChange={setShowRevokeDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Revoke Google Calendar Access</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to revoke access to your Google Calendar? 
              This will remove the connection between your account and the application.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleUnauthorizeGoogle}
              className="bg-destructive text-destructive-foreground"
            >
              Yes, Revoke Access
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default GoogleAuthComponent;